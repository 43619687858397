var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"map"}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"header"},[_vm._v("地图标签管理")]),_c('div',{staticClass:"body"},[(_vm.visible === false)?_c('div',[_c('div',{staticClass:"right",staticStyle:{"margin-bottom":"12px"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("添加")])],1),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"loading":_vm.loading,"pagination":{
            total: _vm.total,
            current: _vm.current,
            pageSize: _vm.pageSize,
            showTotal: (total) => `共 ${total} 条记录`,
          },"rowKey":"id"},on:{"change":_vm.onChange}},[_c('a-table-column',{key:"name",attrs:{"title":"名称","data-index":"name"}}),_c('a-table-column',{key:"method",attrs:{"title":"绘制方式","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [(text.method === 'fill')?_c('span',[_vm._v("填充")]):_vm._e(),(text.method === 'outline')?_c('span',[_vm._v("描边")]):_vm._e()]}}],null,false,2520387476)}),_c('a-table-column',{key:"color",attrs:{"title":"颜色","data-index":"color","align":"center"}}),_c('a-table-column',{key:"control",attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(text)}}},[_vm._v("编辑")]),_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteText(text)}}},[_vm._v("删除")])])]}}],null,false,1647353820)})],1)],1):_c('div',[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"标签名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  initialValue: _vm.editingText.name,
                  rules: [{ required: true, message: '请输入' }],
                },
              ]),expression:"[\n                'name',\n                {\n                  initialValue: editingText.name,\n                  rules: [{ required: true, message: '请输入' }],\n                },\n              ]"}]})],1),_c('a-form-item',{attrs:{"label":"绘制方式"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'method',
                {
                  initialValue: _vm.editingText.method,

                  rules: [{ required: true, message: '请选择' }],
                },
              ]),expression:"[\n                'method',\n                {\n                  initialValue: editingText.method,\n\n                  rules: [{ required: true, message: '请选择' }],\n                },\n              ]"}]},[_c('a-select-option',{attrs:{"value":"outline"}},[_vm._v("描边")]),_c('a-select-option',{attrs:{"value":"fill"}},[_vm._v("填充")])],1)],1),_c('a-form-item',{attrs:{"label":"颜色"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'color',
                {
                  initialValue: _vm.editingText.color,
                  rules: [{ required: true, message: '请输入' }],
                },
              ]),expression:"[\n                'color',\n                {\n                  initialValue: editingText.color,\n                  rules: [{ required: true, message: '请输入' }],\n                },\n              ]"}]})],1),_c('a-form-item',{attrs:{"label":"坐标范围"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'locations',
                {
                  initialValue: _vm.editingText.locations,
                  rules: [{ required: true, message: '请输入' }],
                },
              ]),expression:"[\n                'locations',\n                {\n                  initialValue: editingText.locations,\n                  rules: [{ required: true, message: '请输入' }],\n                },\n              ]"}],attrs:{"disabled":true}}),_c('div',{staticClass:"tip"},[_vm._v("请点击右侧地图获取")])],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(_vm._s(_vm.editingText.id ? "编辑" : "添加"))])],1)],1)],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }