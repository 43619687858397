<template>
  <div>
    <div id="map"></div>

    <div class="card">
      <div class="header">地图标签管理</div>
      <div class="body">
        <div v-if="visible === false">
          <div class="right" style="margin-bottom: 12px">
            <a-button type="primary" @click="add">添加</a-button>
          </div>
          <a-table
            bordered
            :dataSource="list"
            @change="onChange"
            :loading="loading"
            :pagination="{
              total,
              current,
              pageSize,
              showTotal: (total) => `共 ${total} 条记录`,
            }"
            rowKey="id"
          >
            <a-table-column
              key="name"
              title="名称"
              data-index="name"
            ></a-table-column>
            <a-table-column key="method" title="绘制方式" align="center">
              <template slot-scope="text">
                <span v-if="text.method === 'fill'">填充</span>
                <span v-if="text.method === 'outline'">描边</span>
              </template>
            </a-table-column>
            <a-table-column
              key="color"
              title="颜色"
              data-index="color"
              align="center"
            ></a-table-column>
            <a-table-column key="control" title="操作" align="center">
              <template slot-scope="text">
                <a-space>
                  <a href="#" @click.prevent="edit(text)">编辑</a>
                  <a href="#" class="danger" @click.prevent="deleteText(text)"
                    >删除</a
                  >
                </a-space>
              </template>
            </a-table-column>
          </a-table>
        </div>
        <div v-else>
          <a-form :form="form" :colon="false" @submit="handleSubmit">
            <a-form-item label="标签名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    initialValue: editingText.name,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item label="绘制方式">
              <a-select
                v-decorator="[
                  'method',
                  {
                    initialValue: editingText.method,

                    rules: [{ required: true, message: '请选择' }],
                  },
                ]"
              >
                <a-select-option value="outline">描边</a-select-option>
                <a-select-option value="fill">填充</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="颜色">
              <a-input
                v-decorator="[
                  'color',
                  {
                    initialValue: editingText.color,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item label="坐标范围">
              <a-textarea
                :disabled="true"
                v-decorator="[
                  'locations',
                  {
                    initialValue: editingText.locations,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
              <div class="tip">请点击右侧地图获取</div>
            </a-form-item>

            <div class="center">
              <a-space>
                <a-button @click="cancel">取消</a-button>
                <a-button type="primary" html-type="submit">{{
                  editingText.id ? "编辑" : "添加"
                }}</a-button>
              </a-space>
            </div>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchList, remove, add, edit } from "@/api/setting/map-label";

import { Scene } from "@antv/l7";
import { GaodeMapV2 } from "@antv/l7-maps";

export default {
  data() {
    return {
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      form: this.$form.createForm(this),
      editingText: {},
      visible: false,
      selectedLnglat: [],
    };
  },

  mounted() {
    const map = this.initMap();
    this.scene = new Scene({
      id: "map",
      logoVisible: false,
      map: new GaodeMapV2({
        mapInstance: map,
      }),
    });
    this.getList();
  },

  beforeDestroy() {
    // this.scene?.removeAllLayer();
    // this.scene?.removeAllMakers();
    this.scene?.destroy();

    this.map?.clearMap();
    this.map?.destroy();
  },

  methods: {
    initMap() {
      const { AMap } = window;
      if (!AMap) {
        return;
      }
      var map = new AMap.Map("map", {
        center: [119.056708, 33.583976],
        zoom: 15,
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",
      });

      // AMap.plugin(["AMap.Scale", "AMap.HawkEye", "AMap.MapType"], function () {
      //   map.addControl(new AMap.Scale());
      //   map.addControl(new AMap.HawkEye({ isOpen: true }));
      //   map.addControl(new AMap.MapType());
      // });

      this.map = map;

      map.on("click", this.onMapClick);

      return map;
    },

    onMapClick(e) {
      if (this.visible) {
        const { AMap } = window;
        const lnglat = {
          lng: e.lnglat.getLng(),
          lat: e.lnglat.getLat(),
        };
        this.selectedLnglat.push(lnglat);
        this.form.setFieldsValue({
          locations: this.selectedLnglat
            .map((item) => item.lng + "," + item.lat)
            .join(";"),
        });

        var marker = new AMap.Marker({
          position: new AMap.LngLat(lnglat.lng, lnglat.lat),
        });
        marker.on("click", this.onMarkerClick);

        if (this.markers) {
          this.markers.push(marker);
        } else {
          this.markers = [marker];
        }

        this.map.add(marker);
      }
    },

    onMarkerClick(e) {
      console.log("e", e);
      const { lng, lat } = e.target._position;
      console.log("selectedLnglat", this.selectedLnglat, lng, lat);

      const index = this.selectedLnglat.findIndex(
        (item) => parseFloat(item.lng) === lng && parseFloat(item.lat) === lat
      );
      console.log("index", index, this.markers);
      if (index !== -1) {
        this.selectedLnglat.splice(index, 1);

        this.form.setFieldsValue({
          locations: this.selectedLnglat
            .map((item) => item.lng + "," + item.lat)
            .join(";"),
        });

        const marker = this.markers[index];
        if (marker) {
          this.map?.remove(marker);
          this.markers.splice(index, 1);
        }
      }
    },

    getList() {
      this.loading = true;
      const { current, pageSize } = this;
      fetchList({
        pageNum: current,
        pageSize,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    add() {
      this.visible = true;
    },
    edit(text) {
      const { AMap } = window;
      this.editingText = text;
      this.visible = true;
      this.selectedLnglat = text.locations.split(";").map((item) => {
        const arr = item.split(",");
        return {
          lng: arr[0],
          lat: arr[1],
        };
      });

      const markers = this.selectedLnglat.map((lnglat) => {
        var marker = new AMap.Marker({
          position: new AMap.LngLat(lnglat.lng, lnglat.lat),
        });

        marker.on("click", this.onMarkerClick);

        return marker;
      });

      this.markers = markers;

      this.map.add(markers);
    },
    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    cancel() {
      this.form.resetFields();
      this.editingText = {};
      this.visible = false;
      this.map?.clearMap();
      this.selectedLnglat = [];
      this.markers = [];
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.editingText.id) {
            edit({
              id: this.editingText.id,
              ...values,
            }).then(() => {
              this.getList();
              this.cancel();
            });
          } else {
            add({
              ...values,
            }).then(() => {
              this.getList();
              this.cancel();
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
#map {
  position: fixed;
  top: 10vh;
  left: 4vw;
  right: 4vw;
  bottom: 4vh;
  z-index: 10;
}

.card {
  position: fixed;
  top: 10vh;
  left: 4vw;
  width: 50vw;
  z-index: 99;
  background-color: #000;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);

  .header {
    background-color: var(--theme-color);
    color: #fff;
    font-weight: bold;
    padding: 1vh 1vw;
  }
  .body {
    padding: 2vh 1vw;

    .tip {
      font-size: 12px;
      color: #999;
    }
  }
}
</style> 