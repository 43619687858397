


import request from '../request'

export function fetchList(params) {
    return request({
        url: '/basic-service/map/bookmark/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/basic-service/map/bookmark/add',
        method: 'POST',
        data,
    })
}

export function edit(data) {
    return request({
        url: '/basic-service/map/bookmark/update',
        method: 'POST',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/basic-service/map/bookmark/delete',
        method: 'POST',
        data
    })
}


export function fetchDetail({ id }) {
    return request({
        url: '/basic-service/map/bookmark/query/' + id,
    })
}